<template>
  <a-layout>
    <a-layout-sider :trigger="null" collapsible style="position: relative">
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item key="1">
          <user-outlined />
          <a :href="'/login'">登录</a>
        </a-menu-item>
        <a-menu-item key="2">
          <video-camera-outlined />
          <a :href="'/reg'">注册</a>
        </a-menu-item>
        <!-- <a-menu-item key="3">
                    <a :href="'/index'">首页</a>
                </a-menu-item> -->
        <a-menu-item key="4">
          <a :href="'/put_form'">添加</a>
        </a-menu-item>
        <a-menu-item key="5">
          <a :href="'/role'">角色授权管理</a>
        </a-menu-item>
        <a-menu-item key="6">
          <a :href="'/pay'">钱包管理</a>
        </a-menu-item>
        <span v-for="(item, i) in memu_list" :key="i">
          <a-menu-item :key="i">
            <a :href="'/rolenode?name=' + item">{{ item }}</a>
          </a-menu-item>
        </span>
      </a-menu>
    </a-layout-sider>
    <a-layout-content
      :style="{
        margin: '24px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      }"
    >
      <!-- 内容填充区 -->
    </a-layout-content>
  </a-layout>
  <!-- <center>
    <h1>
      网站已备案
      <a href="https://beian.miit.gov.cn/" target="blank"
        >京ICP备2022026867号-1</a
      >
    </h1>
  </center> -->
  <!-- <div class="aa">
        <a-form>
            <h1 v-for="all_one, a_i in all_datas" :key="a_i">
                <font href="#" @click="select = a_i">{{ a_i }}</font>
                <b v-if="a_i == select">·</b>
            </h1>
            <a-form-item>
                <div style="width: 300px;height: 168px;overflow-y:auto;overflow-x:hidden;background-color: aliceblue;">
                    <ul v-for="item, i in all_datas[select]" :key="i" style="margin: 0px;">
                        <li style="margin: 0px;">{{ item['phone'].slice(0, 8) }}:&nbsp;{{ item['value'] }}
                        </li>
                    </ul>
                </div>
                <a-input v-model:value="axx" placeholder="请输入特定的json数据" style="width: 72%; margin-right: 8px"
                    @@keyup.enter.native="addtalk" />
                <a-button type="primary" html-type="submit" @click="addtalk">Submit</a-button>
            </a-form-item>
        </a-form>
    </div> -->
</template>

<script>
export default {
  data() {
    return {
      key: "value",
      memu_list: [],
      axx: "",
      // collapsed: '',
      selectedKeys: [""],
      all_datas: {
        7: [
          { phone: "ab", value: "123" },
          { phone: "db", value: "456" },
        ],
        3: [
          { phone: "abc", value: "1234" },
          { phone: "dbe", value: "5678" },
        ],
      },
      select: 3,
      ws: "",
    };
  },
  methods: {
    get_memu() {
      this.myaxios("http://127.0.0.1:8000/menu_role/", "get")
        .then((res) => {
          // console.log(res);
          if (res.errcode == 0) {
            this.memu_list = res.data.menu;
            // console.log(this.memu_list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addtalk() {
      this.all_datas[this.select].push({
        phone: localStorage.getItem("phone"),
        value: this.axx,
      });
      var ws = new WebSocket(
        "ws://127.0.0.1:8001/websocket/?token=" + localStorage.getItem("token")
      );
      ws.onopen = function () {
        // this.ws.send(JSON.stringify({ "token": localStorage.getItem('token') }))
        console.log("建立连接");
      };
      ws.send(this.axx);
      this.axx = "";
    },
    reconnect() {
      var that = this;
      console.log("再次连接");
      if (that.lock == true) {
        return;
      }
      that.lock = true;
      setTimeout(function () {
        that.get_ws();
        that.lock = false;
      }, 500);
    },
    get_ws() {
      var thatc = this;
      this.ws = new WebSocket(
        "ws://127.0.0.1:8001/websocket/?token=" + localStorage.getItem("token")
      );
      this.ws.onopen = function () {
        // this.ws.send(JSON.stringify({ "token": localStorage.getItem('token') }))
        console.log("建立连接");
      };
      this.ws.onmessage = function (evt) {
        console.log(evt);
        thatc.$message.info(evt.data);
        thatc.myaxios("http://127.0.0.1:8001/suremsg/");
      };
      this.ws.onclose = function () {
        console.log("连接关闭");
        thatc.reconnect();
      };
      // this.ws.send("123")
      // this.ws.onopen
    },
  },
  mounted() {},
  created() {
    this.get_memu();
    // this.get_ws();
  },
};
</script>

<style>
.aa {
  position: absolute;
  float: right;
  margin-top: 100px;
  margin-left: 900px;
  /* background-color: rgba(250, 235, 215, 0); */
  width: 300px;
  height: 200px;
}

/* .aa .inp{ */
/* margin-top: 168px; */
/* } */
</style>