<template>
  <a-layout>
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <mymenu />
    </a-layout-sider>
    <a-layout>
      <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
        <!-- 内容填充区 -->
        <a-input addonBefore="账&emsp;号" v-model:value="phone" />
        <a-input addonBefore="密&emsp;码" v-model:value="password" />
        <a-input addonBefore="验证码" v-model:value="msgcode" />
        <a-button type="primary" html-type="submit" @click="code">获取验证码</a-button>
        <a-select v-model:value="cate" placeholder="{{cate}}">
          <a-select-option value="0">广告主</a-select-option>
          <a-select-option value="1">运&emsp;营</a-select-option>
          <a-select-option value="2">流&emsp;量</a-select-option>
        </a-select>
        <a-button type="primary" html-type="submit" @click="submit">登录</a-button>
      </a-layout-content>
    </a-layout>
  </a-layout>

</template>

<script>
import menu from "./menu.vue";


export default {
  components: {
    mymenu: menu
  },
  data() {
    return {
      key: "value",
      phone: "",
      password: "",
      cate: "0",
      msgcode: "",
    }
  },
  methods: {
    code() {
      console.log(this.phone);
      this.myaxios("/reg/", "get", {
        'phone': this.phone
      }).then((result) => {
        console.log(result);
        if (result.errcode == 0) {
          console.log(result.msg);
        } else {
        }
      })
    },
    submit() {
      this.myaxios('/reg/', "post", {
        'phone': this.phone,
        'code': this.msgcode,
        'password': this.password,
        'cate': this.cate,
      }).then((result) => {
        console.log(result);
        if (result.code == 200) {
          localStorage.setItem("token", result.token)
          localStorage.setItem("phone", result.phone)
          console.log(result.message);
          console.log("欢迎回来@", result.phone);
          this.$toast.success(result.message);

        } else {
          if (result.code == 206) {
            this.code1 = true
          }
        }
      });
    },
  },
  mounted() {
  },
  created() { }
}
</script>

<style scoped>
@import url('../assets/style.css');
</style>