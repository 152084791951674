import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router/index'


import axios from 'axios'
import qs from 'qs'

// import WebSocket from 'web'
// import Vant from 'vant';
// import 'vant/lib/index.css';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

//封装请求
const myaxios = function (url, type, data = {}) {
	if("http" == url.slice(0,4)){
		
		console.log(url);
	}else{
		// url = "http://127.0.0.1:8001" + url
		url = "http://rootmain.top:8000" + url
		// console.log(url.slice(0,4));
	}
	data['token'] = localStorage.getItem('token')
	return new
		Promise((resolve) => {
			//判断
			if (type === "get" || type === "delete") {
				axios({
					method: type,
					url: url,
					params: data
				}).then((result) => {
					resolve(result.data);
				}).catch((err) => { console.log(err); });
			} else {
				axios({
					method: type,
					url: url,
					data: qs.stringify(data)
				}).then((result) => {
					resolve(result.data);
				}).catch((err) => { console.log(err); });
			}
		});
}


const app = createApp(App)

app.config.globalProperties.myaxios = myaxios;
app.config.globalProperties.axios = axios;
app.config.globalProperties.upload_dir = "https://localhost/static/";
app.config.globalProperties.weburl = "http://rootmain.top:8000";
// app.config.globalProperties.weburl = "http://43.140.199.193:8000";


app.use(router);
// app.use(Vant);
app.use(Antd);

app.mount('#app')