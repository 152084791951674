<template>
  <div id="app">
      
    <router-view></router-view>
    <center>
    <h1>
      网站已备案
      <a href="https://beian.miit.gov.cn/" target="blank"
        >京ICP备2022026867号-1</a
      >
    </h1>
  </center>
  </div>
</template>