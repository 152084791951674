import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Register from '../components/register'


var routes = [
  // {
  //   path:'/',
  //   name:'HelloWorld',
  //   component:HelloWorld
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import('../components/login.vue'),
  },
  {
    path: "/role",
    name: "role",
    component: () => import('../components/role.vue'),
  },
  {
    path: '/reg',
    name: 'reg',
    component: Register
  },
  {
    path:'/',
    name:'menu',
    component: () => import("../components/menu.vue")
  },
  // {
    // path: '/' || "/login",
    // name: "Login",
    // component: () => import('../components/login.vue'),
  // },
  {
    path: '/rolenode',
    name: 'rolenode',
    component: () => import("../components/rolenode.vue")
  },
  {
    path: '/merchant_commit',
    name: 'merchant_commit',
    component: () => import("../components/merchant_commit.vue")
  },
  {
    path: '/merchant_check',
    name: 'merchant_check',
    component: () => import("../components/merchant_check.vue")
  },
  {
    path: '/merchant_use',
    name: 'merchant_use',
    component: () => import("../components/merchant_use.vue")
  },
  {
    path: '/put_form',
    name: 'put_form',
    component: () => import("../components/put_form.vue")
  },
  {
    path: '/talk',
    name: 'talk',
    component: () => import("../components/talk.vue")
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import("../components/pay.vue")
  },
  {
    path: '/pay_back',
    name: 'payback',
    component: () => import("../components/pay_back.vue")
  },
]


const router = new createRouter({
  history: createWebHistory(), // history为必填项
  //history:createWebHashHistory(),
  routes,
})

export {
  router
}
